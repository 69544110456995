import React from 'react'

interface Props {
  styles: { [k: string]: string }
}

const AvisoLegalEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Aviso legal</h1>
    <p>
      Esta página Web es propiedad de EL PASO 2.000 TECHNOLOGY, S.L.U., con NIF
      nº B76177880 y domicilio en C/Josefina Mayor, 32. Telde e Inscrita en el
      Registro Mercantil de Las Palmas en el Tomo 2076 Folio 14 Hoja GC-47873.
    </p>
    <br></br>
    <p>
      Para cualquier consulta o propuesta, contáctenos llamando al teléfono 922
      10 94 41 si reside en Tenerife, al teléfono 928 58 53 33 si reside en Gran
      Canaria o en el e-mail atencionalcliente@elpaso2000.com.
    </p>
    <br></br>
    <p>
      Esta página Web se rige por la normativa exclusivamente aplicable en
      España, quedando sometida a ella, tanto nacionales como extranjeros que
      utilicen esta Web.
    </p>
    <p>
      El acceso a nuestra página Web por parte del USUARIO es gratuito y está
      condicionado a la previa lectura y aceptación Íntegra, expresa y sin
      reservas de las presentes CONDICIONES GENERALES DE USO vigentes en el
      momento del acceso, que rogamos lea detenidamente. El USUARIO en el
      momento que utiliza nuestro portal, sus contenidos o servicios, acepta y
      se somete expresamente a las condiciones generales de uso del mismo. Si el
      usuario no estuviere de acuerdo con las presentes condiciones de uso,
      deberá abstenerse de utilizar este portal y operar por medio del mismo.
    </p>
    <p>
      El suministro de datos personales a través de nuestro portal requiere una
      edad mínima de 14 años, o en su caso, disponer de capacidad jurídica
      suficiente para contratar.
    </p>
    <p>
      En cualquier momento podremos modificar la presentación y configuración de
      nuestra Web, ampliar o reducir servicios, e incluso suprimirla de la Red,
      así como los servicios y contenidos prestados, todo ello de forma
      unilateral y sin previo aviso.
    </p>

    <h2 className={styles.section_title}>
      <strong>A. Propiedad intelectual</strong>
    </h2>
    <p>
      Todos los contenidos, textos, imágenes, y códigos fuente son propiedad de
      EL PASO 2000 o de terceros a los que se han adquirido sus derechos de
      explotación, y están protegidos por los derechos de Propiedad Intelectual
      e Industrial.
    </p>
    <p>
      El usuario únicamente tiene derecho a un uso privado de los mismos, sin
      ánimo de lucro, y necesita autorización expresa para modificarlos,
      reproducirlos, explotarlos, distribuirlos o ejercer cualquier derecho
      perteneciente a su titular.
    </p>
    <p>
      EL PASO 2000 es una marca registrada, y está prohibida su reproducción,
      imitación, utilización o inserción de estas marcas sin nuestra debida
      autorización.
    </p>
    <p>
      El establecimiento de enlaces a nuestro portal no confiere ningún derecho
      sobre el mismo. Asimismo, el simple hecho de establecer un enlace a
      nuestra página web no da derecho a otorgarse la categoría de colaborador o
      socio.
    </p>
    <p>
      EL PASO 2000 queda exonerado de responsabilidad por cualquier reclamación
      respecto a los derechos de propiedad intelectual de los artículos e
      imágenes publicadas por terceros en su portal.
    </p>
    <p>
      Está absolutamente prohibida la imitación ya sea total o parcial de
      nuestro portal.
    </p>

    <h2 className={styles.section_title}>
      <strong>B. Condiciones de acceso y uso</strong>
    </h2>
    <p>
      El acceso a nuestra página Web es gratuito y no exige suscripción o
      registro previo. Sin embargo, determinados servicios son de acceso
      restringido a determinados usuarios registrados y requieren la
      identificación mediante contraseñas. Estos servicios quedarán debidamente
      identificados en la Web.
    </p>
    <p>
      El envío de datos personales implica la aceptación expresa por parte del
      USUARIO de nuestra política de privacidad.
    </p>
    <p>
      El usuario debe acceder a nuestra página Web conforme a la buena fe, las
      normas de orden público y a las presentes Condiciones Generales de uso. El
      acceso a nuestro sitio Web se realiza bajo la propia y exclusiva
      responsabilidad del usuario, que responderá en todo caso de los daños y
      perjuicios que pueda causar a terceros o a nosotros mismos.
    </p>
    <p>
      El usuario tiene expresamente prohibida la utilización y obtención de los
      servicios y contenidos ofrecidos en la presente página web, por
      procedimientos distintos a los estipulados en las presentes condiciones de
      uso.
    </p>
    <p>
      El USUARIO tiene prohibido cualquier tipo de acción sobre nuestro portal
      que origine una excesiva sobrecarga de funcionamiento a nuestros sistemas
      informáticos, así como la introducción de virus, o instalación de robots,
      o software que altere el normal funcionamiento de nuestra web, o en
      definitiva pueda causar daños a nuestros sistemas informáticos.
    </p>
    <p>
      Teniendo en cuenta la imposibilidad de control respecto a la información,
      contenidos y servicios que contengan otras páginas web a los que se pueda
      acceder a través de los enlaces que nuestra página web pueda poner a su
      disposición, le comunicamos que EL PASO 2000, queda eximida de cualquier
      responsabilidad por los daños y perjuicios de toda clase que pudiesen
      derivar por la utilización de esas páginas web, ajenas a nuestra empresa,
      por parte del usuario.
    </p>
    <p>
      EL PASO 2000 se reserva el derecho unilateral y sin preaviso de dar de
      baja a cualquier USUARIO que la organización entienda que ha vulnerado las
      condiciones que rigen el uso de nuestra página web, sin que el USUARIO
      tenga derecho a reclamación de ningún tipo por este tipo de acciones.
      Asimismo, se reserva el derecho de ejercitar las acciones legales
      oportunas contra aquellos que vulneren las presentes condiciones generales
      de uso, aceptando el USUARIO que la no iniciación de estas acciones no
      constituye una renuncia formal a los mismas, permaneciendo éstas vigentes
      hasta los plazos legales de prescripción de las infracciones.
    </p>

    <h2 className={styles.section_title}>
      <strong>C. Política de privacidad</strong>
    </h2>
    <p>
      La confidencialidad y la seguridad son valores primordiales de EL PASO
      2000 y, en consecuencia, asumimos el compromiso de garantizar la
      privacidad del Usuario en todo momento y de no recabar información
      innecesaria.
    </p>
    <p>
      El suministro de datos personales a través de nuestro portal requiere una
      edad mínima de 14 años y la aceptación expresa de nuestra Política de
      Privacidad.
    </p>

    <h2 className={styles.section_title}>
      <strong>D. Responsabilidades</strong>
    </h2>
    <p>
      Al poner a disposición del usuario esta página Web queremos ofrecerle un
      servicio de calidad, utilizando la máxima diligencia en la prestación del
      mismo, así como en los medios tecnológicos utilizados. No obstante, no
      responderemos de la presencia de virus y otros elementos que de algún modo
      puedan dañar el sistema informático del usuario.
    </p>
    <p>
      EL PASO 2000 no garantiza que la disponibilidad del servicio sea continua
      e ininterrumpida, por circunstancias originadas por problemas en la red de
      Internet, averías en los dispositivos informáticos u otras circunstancias
      imprevisibles, de manera que el USUARIO acepta soportar dentro de unos
      límites razonables estas circunstancias, por lo que renuncia expresamente
      a reclamar a EL PASO 2000 cualquier responsabilidad por los posibles
      fallos, errores y uso del servicio.
    </p>
    <p>
      El USUARIO asume toda la responsabilidad derivada del uso de nuestra
      página web, siendo el único responsable de todo efecto directo o indirecto
      que sobre la página web se derive, incluyendo, de forma enunciativa y no
      limitativa, todo resultado económico, técnico y/o jurídico adverso, así
      como la defraudación de las expectativas generadas por nuestro portal,
      obligándose el usuario a mantener indemne a EL PASO 2000 por cualesquiera
      reclamaciones derivadas, directa o indirectamente de tales hechos.
    </p>
    <p>
      EL PASO 2000 no garantiza la exactitud, veracidad y vigencia de los
      contenidos de esta página web, ya sean propios, de terceros, o enlazables
      a otras webs, quedando totalmente exonerada de cualquier responsabilidad
      derivada del uso de la mismos.
    </p>
    <p>
      EL PASO 2000 queda exonerada de cualquier responsabilidad derivada de
      cualquier reclamación, incluido el pago de honorarios a abogados, por las
      demandas y reclamaciones originadas por terceros por el incumplimiento por
      parte del USUARIO de nuestras condiciones de uso, acceso y política de
      privacidad, o cualquiera otra reclamación por el incumplimiento de la
      legislación vigente.
    </p>
    <p>
      El USUARIO reconoce que ha entendido toda la información respecto a las
      condiciones de uso de nuestro portal, y reconoce que son suficientes para
      la exclusión del error en las mismas, y por lo tanto, las acepta integra y
      expresamente.
    </p>
    <p>
      El USUARIO es plenamente consciente de que la mera navegación por la
      presente página web, así como la utilización de sus servicios, implica la
      aceptación de las presentes condiciones de uso.
    </p>
    <p>
      Todo lo referente a nuestra página web, se rige exclusivamente por las
      leyes españolas. En el caso de que se produzca cualquier tipo de
      discrepancia o diferencia entre las partes en relación con la
      interpretación y contenido de la presente página web, todas las partes se
      someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y
      Tribunales de Lleida.
    </p>

    <h2 className={styles.section_title}>
      <strong>
        E. Vigencia de las condiciones generales de acceso a la web
      </strong>
    </h2>
    <p>
      Las presentes Condiciones Generales de Uso han sido modificadas con fecha
      25/05/2018. En cualquier momento podemos proceder a su modificación: por
      favor, compruebe la fecha de emisión en cada ocasión en que se conecte a
      nuestra página Web y así tendrá la certeza de que no se ha producido
      modificación alguna que le afecte.
    </p>
    <p>
      Para cualquier cuestión respecto a las Condiciones de Uso de nuestra
      página web, puede ponerse en contacto con nosotros en los datos arriba
      indicados, o con Lant Abogados en info@lant-abogados.com o en{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lant-abogados.com/">
        www.lant-abogados.com
      </a>
      .
    </p>
  </div>
)

const AvisoLegalContents = ({ ...props }: Props) => <AvisoLegalEs {...props} />

export default AvisoLegalContents
